import React, { useRef, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'

const StaticMap = ({ sample, geojson }) => {
  const pointGeojson = geojson.features.find(feature => feature.properties.sample_id === sample.id) || null
  const mapContainer = useRef(null)
  const map = useRef(null)
  
  useEffect(() => {
    if (!pointGeojson) return

    const latitude = pointGeojson.geometry.coordinates[1]
    const longitude = pointGeojson.geometry.coordinates[0]

    mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_API_KEY

    if (map.current) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [longitude, latitude],
      zoom: 15,
      interactive: false,
      preserveDrawingBuffer: true 
    })
    
    new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map.current)
    
  }, [pointGeojson])

  if (!pointGeojson) {
    return (
      <div className='border border-gray-400 h-full w-full flex items-center justify-center flex-col bg-gray-200 p-1'>
        <p className='text-xs py-2 bg-gray-200 font-semibold w-full rounded text-center z-10'>Coordinates not found</p>
      </div>
    )
  }

  return (
    <div className='border border-gray-400 h-full w-full flex items-center justify-center flex-col bg-gray-200 p-1'>
      <div ref={mapContainer} className='w-full h-full' />
    </div>
  )
}

export default StaticMap
