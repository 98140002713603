import React, { useState, useEffect, useRef, useMemo } from 'react'
import { toPng } from 'html-to-image';
import logo from '../../../../../assets/images/wordmark.png'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import DiagnosticRatios from './diagnosticReport/diagnosticRatios'
import SampleMetadata from './sampleMetadata'
import ReferenceTable from './referenceTable'
import buildDoubleRatioPlot from '../../doubleRatioPlot/buildDoubleRatioPlot'
import fixedGridData from '../../doubleRatioPlot/fixedGridData'
import StaticMap from '../../../shared/staticMap'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'
import { buildReferenceChartOptions, buildPahChart, sampleScatterData, buildAlkylatedReferenceChartOptions, buildAlkylatedChartOptions, referenceScatterPoints, titleBuilder } from '../helpers/chartHelpers'
import { resultTextColor } from './helpers/analysisHelper'
import { fetchParentReferenceData, fetchHistogramData, fetchAlkylatedData, fetchDiagnosticRatiosData } from './helpers/chartsApiHelper'
import { setExportData } from './helpers/pdfHelper'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const downloadReportCard = async (divRef, selectedSample, setLoading) => {
  if (!divRef.current) return;
  setLoading(true)
  try {
    const dataUrl =  await toPng(divRef.current, {
      backgroundColor: null,
      filter: (node) => {
        return !(node.tagName === 'svg' && node.getAttribute('data-mapbox-logo') !== null);
      },
      cacheBust: true,
      useCORS: true,
      fontEmbedCSS: false
    });
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${selectedSample.sample_name_for_json} PAH report.png`;
    link.click();
  } catch (error) {
    console.error('Error capturing divRef as PNG:', error);
  }
  setLoading(false)
};

const Charts = ({ selectedSample, site, processed, exporting, getExportedData }) => {
  const { additionalData, labSource, mode, geoJSON } = usePahFingerprinting()
  const [sampleHistogramData, setSampleHistogramData] = useState(null)
  const [sampleExtendedHistogramData, setSampleExtendedHistogramData] = useState(null)
  const [references, setReferences] = useState(null)
  const [alkylatedReferences, setAlkylatedReferences] = useState(null)
  const [data, setData] = useState(null)
  const [scatterData, setScatterData] = useState(null)
  const [referenceScatterData, setReferenceScatterData] = useState(null)
  const [ratioChartOptions, setRatioChartOptions] = useState(null)
  const [sampleExportData, setSampleExportData] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const isAlkylated = mode === 'Alkylated'
  
  // Ref for exporting
  const divRef = useRef(null)

  const hasRatioPlot = (data) => {
    if (!data) return false
    return true
  } 

  useEffect(() => {
    if (selectedSample && site) {
      fetchParentReferenceData(site.id, selectedSample.id, setReferences, setReferenceScatterData)
      fetchHistogramData(site.id, selectedSample.id, setSampleHistogramData)
      fetchDiagnosticRatiosData(site.id, selectedSample.id, setData, setScatterData)
    }
  },[selectedSample, site])

  useEffect(() => {
    if (isAlkylated) fetchAlkylatedData(mode, site, selectedSample, labSource, setAlkylatedReferences, setSampleExtendedHistogramData)
  }, [mode])

  useEffect(() => {
    scatterData && setRatioChartOptions(buildDoubleRatioPlot(scatterData, selectedSample.sample_name_for_json, selectedSample.sample_location.name))
  }, [scatterData])

  useEffect(() => {
    if (referenceScatterData && scatterData && additionalData) {
      const referenceList = referenceScatterData.map(reference => reference.name)
      const additionalScatterData = additionalData.filter(data => !referenceList.includes(data.name))
      const hasAdditionalData = additionalScatterData.length > 0
      const referencePoints = referenceScatterData.map(reference => referenceScatterPoints(reference))
      setRatioChartOptions(prev => {
        return {
          ...prev,
          legend: {
            ...prev.legend,
            layout: !hasAdditionalData ? 'vertical' : 'horizontal',
            itemWidth: !hasAdditionalData > 0 ? 250 : 150,
          },
          series: [
            ...fixedGridData(),
            sampleScatterData(selectedSample, scatterData),
            ...referencePoints,
            ...additionalScatterData,
          ]
        }
      })
    }
  }, [referenceScatterData, scatterData, additionalData])

  useEffect(() => {
    const handleExportData = async () => {
      const ratioPlotLoaded = hasRatioPlot(scatterData) ? ratioChartOptions && scatterData : true;
      const alkylatedHistogramsLoaded = isAlkylated ? sampleExtendedHistogramData && alkylatedReferences : true;
      const dataLoaded = sampleHistogramData && references && referenceScatterData && ratioPlotLoaded && alkylatedHistogramsLoaded;
  
      if (dataLoaded) {
        processed()
        setDataLoaded(true)
        if (exporting) {
          try {
            const exportData = await setExportData(selectedSample, divRef)
            setSampleExportData(exportData)
          } catch (error) {
            console.error('Error generating export data:', error);
          }
        }
      }
    };
    
    handleExportData()
  }, [references, sampleHistogramData, scatterData, referenceScatterData, ratioChartOptions, additionalData, exporting, sampleExtendedHistogramData, alkylatedReferences])
  
  useEffect(() => {
    if (sampleExportData) getExportedData(sampleExportData)
  }, [sampleExportData])

  const contributionChart = useMemo (() => (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id}>
      <HighchartsReact
        highcharts={Highcharts}
        options={buildPahChart(selectedSample?.sample_name_for_json, 'Contribution (%)', sampleHistogramData, sampleHistogramData?.map(item => item.name), '%', null, 300, null)}
      />
    </div>
  ), [sampleHistogramData])

  const referenceContributionCharts = useMemo (()=> references?.map((reference, i) => {
    const title = titleBuilder(reference)
    const data = reference?.contribution_data.map(item => { return { name: item.name, contribution: item.contribution }})
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildReferenceChartOptions(title, data, 'contribution', 'Contribution (%)', 300, null)}
        />
      </div>
    )
  }), [references])

  const doubleRatioChart = useMemo (() => (
    ratioChartOptions ? 
    <HighchartsReact
      highcharts={Highcharts}
      options={ratioChartOptions}
    />
    :
    <HighchartsReact
      highcharts={Highcharts}
      options={buildDoubleRatioPlot([], 'Insufficient data for sample', selectedSample.sample_location.name)}
    />
  ), [ratioChartOptions])

  const extendedContributionChart = useMemo (() => (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id}>
      <HighchartsReact
        highcharts={Highcharts}
        options={buildAlkylatedChartOptions(selectedSample?.sample_name_for_json, 'Contribution (%)', sampleExtendedHistogramData?.series, sampleExtendedHistogramData?.categories, sampleExtendedHistogramData?.total_concentration, true, labSource, null)}
      />
    </div>
  ), [sampleExtendedHistogramData])

  const referenceExtendedContributionCharts = useMemo (() => isAlkylated && alkylatedReferences?.map((reference, i) => {
    const title = titleBuilder(reference)
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildAlkylatedReferenceChartOptions(title, 'Contribution (%)', reference?.contribution_data, true, labSource, null)}
        />
      </div>
    )
  }), [alkylatedReferences])

  return  (
    <>
      <div className='flex justify-end mx-1 relative mt-4'>
        <div className='absolute -top-5 right-4'>
          <button 
            className={`text-md border-2 border-black p-1 rounded font-semibold flex items-center justify-items-center ${(loading || !dataLoaded) ? 'bg-gray-200 cursor-not-allowed' : 'bg-green-200 hover:bg-green-300'}`}
            onClick={() => downloadReportCard(divRef, selectedSample, setLoading)}
            disabled={loading || !dataLoaded}
          >
            {dataLoaded ? loading ? 'EXPORTING...' : 'EXPORT' : 'LOADING...'}
          </button>
        </div>
      </div>
      <div ref={divRef} className='border-2 px-5 pb-5 rounded bg-blue-200 w-full'>
        <div className='flex justify-between items-center font-semibold w-full mb-1'>
          <div className='flex items-center w-1/2 py-1'>
            <img src={logo} alt='logo' className='h-10 mr-2' />
            <h1 className='text-lg font-semibold my-2 w-1/2'>{selectedSample.sample_name_for_json}</h1>
          </div>
          <h1 className={`${resultTextColor(data?.formatted_overall_result)} text-lg font-semibold my-2 w-1/2 text-right`}>{data?.formatted_overall_result}</h1>
        </div>
        <div className='w-full flex'>
          <div className='bg-white w-2/5'>
            <h3 className='font-semibold px-1 bg-gray-200'>Double Ratio Plot</h3>
            <div className='flex justify-center'>
              {doubleRatioChart}
            </div>
          </div>
          <div className='flex flex-col w-3/5 ml-1'>
            <div className='w-full mb-1'>
              <DiagnosticRatios data={data} />
            </div>
            <div className='flex w-full'>
              <div className='flex flex-col w-1/2 h-96 mr-1'>
                <div className={`w-full mb-1 ${isAlkylated ? 'h-1/3' : 'h-1/2'}`}>
                  <SampleMetadata selectedSample={selectedSample} />
                </div>
                <div className='w-full mb-1 h-2/3'>
                  <ReferenceTable references={references} alkylatedReferences={alkylatedReferences} isAlkylated={isAlkylated} />
                </div>
              </div>
              <div className='w-1/2 h-96'>
                <StaticMap sample={selectedSample} geojson={geoJSON} />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white border rounded my-1'>
          <p className='text-lg font-semibold text-center bg-gray-200'>PARENT PAH HISTOGRAMS</p>
          <div className='flex w-full p-1'>
            <div className='w-1/2'>
              {sampleHistogramData && contributionChart}
            </div>
            <div className='w-1/2'>
              {referenceContributionCharts && referenceContributionCharts[0]}
            </div>
          </div>
          <div className='flex w-full p-1'>
            <div className='w-1/2'>
              {referenceContributionCharts && referenceContributionCharts[1]}
            </div>
            <div className='w-1/2'>
              {referenceContributionCharts && referenceContributionCharts[2]}
            </div>
          </div>
        </div>
        {isAlkylated && <div className='bg-white border rounded my-1'>
          <p className='text-lg font-semibold text-center bg-gray-200'>ALKYLATED PAH HISTOGRAMS</p>
          <div className='flex w-full p-1'>
            <div className='w-1/2'>
              {sampleExtendedHistogramData && extendedContributionChart}
            </div>
            <div className='w-1/2'>
              {referenceExtendedContributionCharts && referenceExtendedContributionCharts[0]}
            </div>
          </div>
          <div className='flex w-full p-1'>
            <div className='w-1/2'>
              {referenceExtendedContributionCharts && referenceExtendedContributionCharts[1]}
            </div>
            <div className='w-1/2'>
              {referenceExtendedContributionCharts && referenceExtendedContributionCharts[2]}
            </div>
          </div>
        </div>}
      </div>
    </>
  )
}

export default Charts
