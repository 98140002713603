import React, { useEffect, useState } from 'react'
import axios from "axios";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsReact from 'highcharts-react-official';
import { downloadZip } from "client-zip"
import getChartConfig from './chartConfig';
import _ from 'lodash';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

const Export = ({ site, saltPrint }) => {
  const [saltPrintSamples, setSaltPrintSamples] = useState(null)
  const [loading, setLoading] = useState(true);
  const [groupedAndSortedSamples, setGroupedAndSortedSamples] = useState({});
  const [loadedGroups, setLoadedGroups] = useState([]);

  // Get URL parameters once on mount
  const params = new URLSearchParams(window.location.search);
  const scaleMode = params.get('scaleMode') || 'auto';
  const customScale = params.get('customScale') ? parseInt(params.get('customScale')) : null;

  useEffect(() => {
    const fetchSamples = async () => {
      try {
        const response = await axios.get(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples.json`);
        const grouped = _.groupBy(response.data, (sample) => sample.sample.sample_location.name);
        const sortedGroups = _.mapValues(grouped, (samples) =>
          _.sortBy(samples, (sample) => parseFloat(sample.sample.sample_depth))
        );
        const orderedGroups = _.fromPairs(_.sortBy(Object.entries(sortedGroups), [0]));

        setSaltPrintSamples(response.data);
        setGroupedAndSortedSamples(orderedGroups);
        setLoadedGroups(Object.keys(orderedGroups).slice(0, 3));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching samples:', error);
        setLoading(false);
      }
    };

    fetchSamples();
  }, [site.id, saltPrint.id]);

  useEffect(() => {
    if (!loading && groupedAndSortedSamples) {
      const allGroupNames = Object.keys(groupedAndSortedSamples);
      const loadNextGroup = () => {
        setLoadedGroups((prev) => {
          const nextIndex = prev.length;
          if (nextIndex < allGroupNames.length) {
            return [...prev, allGroupNames[nextIndex]];
          }
          return prev;
        });
      };

      const timer = setInterval(() => {
        if (loadedGroups.length < allGroupNames.length) {
          loadNextGroup();
        } else {
          clearInterval(timer);
        }
      }, 500);

      return () => clearInterval(timer);
    }
  }, [loading, groupedAndSortedSamples, loadedGroups]);

  const exportCharts = async () => {
    try {
      const chartElements = document.querySelectorAll('.highcharts-container svg');
      if (!chartElements.length) return;

      const files = await Promise.all(
        Array.from(chartElements).map(async (svg, index) => {
          const sample = saltPrintSamples[index];
          const svgData = new XMLSerializer().serializeToString(svg);
          const canvas = document.createElement('canvas');
          canvas.width = 1200;
          canvas.height = 1200;

          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          const img = new Image();
          const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
          const url = URL.createObjectURL(svgBlob);

          return new Promise((resolve) => {
            img.onload = () => {
              const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
              const x = (canvas.width - img.width * scale) / 2;
              const y = (canvas.height - img.height * scale) / 2;

              ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

              canvas.toBlob((blob) => {
                URL.revokeObjectURL(url);
                resolve({
                  name: `${sample.sample.sample_location.name}_${sample.sample.sample_depth}.png`,
                  input: blob
                });
              }, 'image/png', 1.0);
            };
            img.src = url;
          });
        })
      );

      const blob = await downloadZip(files).blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${site.title}_salt_prints.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error exporting charts:', error);
      alert('Error exporting charts. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl">Loading samples...</div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold mb-2">{site.title}</h1>
          <h2 className="text-xl">{saltPrint.title}</h2>
        </div>
        <button onClick={exportCharts} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
          Export All as PNG
        </button>
      </div>

      {loadedGroups.map((groupName) => {
        const samples = groupedAndSortedSamples[groupName];
        return (
          <div key={groupName} className="mb-8">
            <h3 className="text-lg font-semibold mb-4 bg-gray-100 p-2 rounded">
              {groupName}
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {samples.map((sample) => (
                <div key={sample.id} className="bg-white rounded-lg shadow p-4">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getChartConfig(sample, {
                      backgroundColor: 'white',
                      scaleMode: scaleMode,
                      customScale: customScale,
                      height: 300
                    })}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {loadedGroups.length < Object.keys(groupedAndSortedSamples).length && (
        <div className="text-center py-4">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          <p className="mt-2 text-gray-600">Loading more samples...</p>
        </div>
      )}
    </div>
  );
};

export default Export;