import React from "react";
import SaltPrintDataRow from "../shared/table/saltPrintDataRow";
import SaltPrintTableHeader from "../shared/table/saltPrintTableHeader";
import { useSaltPrintContext } from "../../contexts/saltPrint";

const Table = () => {
  const { chemicals, saltPrintSamples, displayedSamples } = useSaltPrintContext();

  if (!saltPrintSamples || !chemicals) {
    return <div>Loading...</div>;
  }

  const hasUniqueIds = saltPrintSamples.some(sample => sample.sample?.name);
  const displayedData = displayedSamples || saltPrintSamples;

  return (
    <div className="h-full">
      <table className="dataVaultTable">
        <SaltPrintTableHeader
          chemicals={chemicals}
          hasUniqueIds={hasUniqueIds}
        />
        <tbody>
          {displayedData.map(sample => (
            <SaltPrintDataRow
              key={sample.id}
              sample={sample}
              chemicalHeaders={chemicals}
              hasUniqueIds={hasUniqueIds}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;