import React, { useState, useEffect } from "react";
import ChemicalHeader from "./chemicalHeader";
import {
  useSaltPrintContext,
  useSaltPrintAPI,
} from "../../../contexts/saltPrint";

const SaltPrintTableHeader = ({ chemicals, hasUniqueIds }) => {
  const { saltPrintSamples, selectedSampleIds } = useSaltPrintContext();
  const { setSelectedSampleIds, clearSelectedSampleIds } = useSaltPrintAPI();
  const [isSelecting, setIsSelecting] = useState(false);
  const hasMethods = chemicals.some((chemical) => chemical?.analytical_method);

  // Reset isSelecting when all samples are loaded
  useEffect(() => {
    if (selectedSampleIds.length === saltPrintSamples?.length) {
      setIsSelecting(false);
    }
  }, [selectedSampleIds.length, saltPrintSamples]);

  const handleToggleAll = () => {
    if (selectedSampleIds.length === 0) {
      // Start selecting
      setIsSelecting(true);
      const allIds = saltPrintSamples.map((sample) => sample.id);
      setSelectedSampleIds(allIds);
    } else {
      // Deselect all
      clearSelectedSampleIds();
      setIsSelecting(false);
    }
  };

  let buttonText = "Select All";
  let buttonClass = "text-sm px-2 py-1 rounded transition-colors ";

  if (isSelecting && selectedSampleIds.length > 0) {
    buttonText = "Stop Loading";
    buttonClass += "bg-yellow-500 hover:bg-yellow-600 text-white";
  } else if (selectedSampleIds.length > 0) {
    buttonText = "Deselect All";
    buttonClass += "bg-red-500 hover:bg-red-600 text-white";
  } else {
    buttonClass += "bg-blue-500 hover:bg-blue-600 text-white";
  }

  return (
    <thead className="uppercase sticky left-0 top-0 z-20">
      <tr>
        <th className="border-none px-2 py-1">
          <button onClick={handleToggleAll} className={buttonClass}>
            {buttonText}
          </button>
        </th>
        <th className="sticky left-0 top-0 bg-gray-200 px-2 py-1">
          Sample Name
        </th>
        {hasUniqueIds && (
          <th className="border-none px-2 py-1">Unique Sample ID</th>
        )}
        <th className="border-none px-2 py-1">Depth</th>
        <th className="border-none px-2 py-1">Sample Group</th>
        <th className="border-none px-2 py-1">Status</th>
        <th className="border-none px-2 py-1">Sample Date</th>
        <th className="border-none px-2 py-1">Matrix</th>
        {chemicals.map((chemical, index) => (
          <ChemicalHeader
            key={index}
            chemical={chemical}
            hasMethods={hasMethods}
          />
        ))}
      </tr>
    </thead>
  );
};

export default SaltPrintTableHeader;
