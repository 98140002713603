import React from "react";
import FilterContainer from "./filterContainer";
import BaseMapContainer from "./baseMapContainer";
import ChartToggle from "../shared/chartToggle";
import SaltPrintSamples from "./saltPrintSamples";
import Table from "./table";
import { useSaltPrintContext, useSaltPrintAPI } from "../../contexts/saltPrint";

const MainContent = ({ site, saltPrint, showChart, setShowChart }) => {
  const { scaleMode, customScale } = useSaltPrintContext();
  const { setScaleMode, setCustomScale } = useSaltPrintAPI();

  const handleSeeAllCharts = () => {
    const params = new URLSearchParams({
      scaleMode: scaleMode,
      ...(customScale !== null && { customScale: customScale.toString() })
    });
    window.open(`/sites/${site.id}/salt_prints/${saltPrint.id}/print?${params.toString()}`, "_blank");
  };

  const handleCustomScaleChange = (e) => {
    const value = Math.min(Math.max(0, parseInt(e.target.value, 10) || 0), 100);
    setCustomScale(value);
  };

  return (
    <>
      <div className="flex justify-between items-center p-4 bg-gray-100">
        <h1 className="text-xl font-bold">{saltPrint.title}</h1>
        <div className="flex items-center space-x-4">
          <span className="mr-3 text-gray-700 font-medium">Chart Scale:</span>
          <div className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => setScaleMode("auto")}
              className={`px-3 py-1.5 text-sm font-medium border rounded-l-lg ${scaleMode === "auto" ? "bg-blue-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"}`}
            >
              Auto
            </button>
            <button
              type="button"
              onClick={() => setScaleMode("max")}
              className={`px-3 py-1.5 text-sm font-medium border-t border-b ${scaleMode === "max" ? "bg-blue-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"}`}
            >
              Maximum
            </button>
            <button
              type="button"
              onClick={() => setScaleMode("custom")}
              className={`px-3 py-1.5 text-sm font-medium border rounded-r-lg ${scaleMode === "custom" ? "bg-blue-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"}`}
            >
              Custom
            </button>
          </div>
          {scaleMode === "custom" && (
            <input
              type="number"
              min="0"
              max="100"
              className="w-20 px-2 py-1.5 text-sm border rounded"
              placeholder="%"
              onChange={handleCustomScaleChange}
              defaultValue={customScale || ""}
            />
          )}
          <button
            onClick={handleSeeAllCharts}
            className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            See All Charts
          </button>
        </div>
      </div>
      
      <FilterContainer />
      <div className="h-map flex relative">
        <div className={`relative h-map ${showChart ? "w-1/3" : "w-0"}`}>
          <BaseMapContainer showChart={showChart} />
          {showChart && (
            <div className="absolute right-0 top-9 z-20">
              <ChartToggle showChart={showChart} setShowChart={setShowChart} />
            </div>
          )}
        </div>
        {!showChart && (
          <div className="absolute left-0 top-9 z-20">
            <ChartToggle showChart={showChart} setShowChart={setShowChart} />
          </div>
        )}
        <div className={`${showChart ? "w-2/3" : "w-full"} h-map overflow-hidden`}>
          <div className="h-full overflow-y-auto">
            <SaltPrintSamples showChart={showChart} />
          </div>
        </div>
      </div>
      <div className="h-1/2 overflow-auto">
        <div className="h-full">
          <Table />
        </div>
      </div>
    </>
  );
};

export default MainContent;