import axios from "axios"
import { toPng } from 'html-to-image';

export const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/double_ratio_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'Report.pdf'
  link.click()
}

export const generatePdfReport = (data, site, getPdfReportData) => {
  const encodedExportData = data.map(data => {
    return { 
      sample_id: data.sample_id,
      report_card: data.report_card
    }
  })

  getPdfReportData(site.id, encodedExportData).then((response) => downloadReport(response.data))
}

export const setExportData = async (selectedSample, divRef) => {
  let reportCardDiv = null;
  if (divRef?.current) {
    try {
      reportCardDiv = await toPng(divRef.current, {
        backgroundColor: null,
        filter: (node) => {
          return !(node.tagName === 'svg' && node.getAttribute('data-mapbox-logo') !== null);
        },
        cacheBust: true,
        useCORS: true,
        fontEmbedCSS: false
      });
    } catch (error) {
      console.error('Error capturing div screenshot using html-to-image:', error);
    }
  }
  return {
    sample_id: selectedSample.id,
    report_card: reportCardDiv
  }
}
