import React, { useState } from "react";
import BaseMap from "../map/baseMap";
import TableContainerSites from "./tableContainerSites";
import NoSite from "./noSite" 

const Index = ({sites, geojson, mapType, newSitePath}) => {
  const [showPopups, setShowPopups ] = useState(false)
  
  return <>
    {sites.length > 0 ?
      <div className="flex flex-col h-full bg-gray-100">
        <div className="flex justify-between items-center">
          <h1 className='ml-3'>Sites</h1>
          <button className="btn btn-primary mb-2" onClick={() => window.location.href = newSitePath}>
            <i className="fa fa-plus mr-2"/>Add Site
          </button>
        </div>
        <div className="h-map">
          <BaseMap geojson={geojson} mapType={mapType} showPopups={showPopups} setShowPopups={setShowPopups} />
        </div>
        <TableContainerSites sites={sites} />
      </div>
      :
      <NoSite newSitePath={newSitePath} />
    }
  </> 
};

export default Index;
