import React from 'react'

const ReferenceTable = ({ references, alkylatedReferences, isAlkylated }) => {
  return (
    <>
      {references &&
        <div className= {`bg-white ${isAlkylated ? 'h-1/2' : 'h-full'}`}>
          <h3 className='font-semibold bg-gray-200 px-1'>Reference Similarities</h3>
          <div className='p-2'>
            <table className="table-fixed w-full">
              <thead>
                <tr>
                  <th className='w-7/12 font-semibold text-sm text-left'>Reference</th>
                  <th className='w-2/12 font-semibold text-sm text-center'>Type</th>
                  <th className='w-3/12 font-semibold text-sm text-center'>Similarity</th>
                </tr>
              </thead>
              <tbody>
                {references.map((reference, index) => {
                  return (
                    <tr key={index} className='text-xs text-left'>
                      <td className='w-1/2'>{reference.name}</td>
                      <td className='w-1/4 text-center'>{reference.source_type || "Library"}</td>
                      <td className='w-1/4 text-center'>{reference.similarity.toFixed(2)}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
      {isAlkylated && alkylatedReferences &&
        <div className='bg-white mt-1 h-1/2'>
          <h3 className='font-semibold bg-gray-200 px-1'>Alkylated Reference Similarities</h3>
          <div className='p-2'>
            <table className="table-fixed w-full">
              <thead>
                <tr>
                  <th className='w-7/12 font-semibold text-sm text-left'>Reference</th>
                  <th className='w-2/12 font-semibold text-sm text-center'>Type</th>
                  <th className='w-3/12 font-semibold text-sm text-center'>Similarity</th>
                </tr>
              </thead>
              <tbody>
                {alkylatedReferences.map((reference, index) => {
                  return (
                    <tr key={index} className='text-xs text-left'>
                      <td className='w-1/2'>{reference.name}</td>
                      <td className='w-1/4 text-center'>{reference.source_type || "Library"}</td>
                      <td className='w-1/4 text-center'>{reference.similarity.toFixed(2)}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

export default ReferenceTable
