import mapboxgl from 'mapbox-gl';

/** This helper function gets the bounds of a geojson object.
*
* @returns the bounds of the given GeoJSON as a [LngLatBounds](https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatbounds), or `undefined` otherwise.
*/
export const boundsHelper = (geojson) => {
  // If there are no features, return 'undefined'
  if (!geojson || geojson.features.length === 0) return;
  const bounds = new mapboxgl.LngLatBounds();
    geojson.features.forEach(feature => {
      bounds.extend(feature.geometry.coordinates);
    });

  return bounds;
};
