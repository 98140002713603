import React from "react";
import ChemicalValue from "./chemicalValue";
import {
  useSaltPrintAPI,
  useSaltPrintContext,
} from "../../../contexts/saltPrint";

const SaltPrintDataRow = ({
  sample,
  chemicalHeaders = [],
  hasUniqueIds = false,
}) => {
  const { addSelectedSampleId, removeSelectedSampleId } = useSaltPrintAPI();
  const { selectedSampleIds } = useSaltPrintContext();

  const chemicalValues =
    sample?.sample?.sample_values || sample.sample_values || [];
  const sampleInfo = sample?.sample || sample;

  const isSampleSelected = selectedSampleIds.includes(sample.id);

  const handleCheckboxChange = (e) => {
    if (isSampleSelected) {
      removeSelectedSampleId(sample.id);
    } else {
      addSelectedSampleId(sample.id);
    }
  };

  const sampleDepth =
    sampleInfo.sample_depth !== null ? sampleInfo.sample_depth : "-";
  const displayedDepth = sampleInfo.depth_range
    ? sampleInfo.depth_range
    : sampleDepth;

  return (
    <tr className="text-xs bg-white hover:bg-blue-100">
      <td className="text-center sticky left-0 z-10 bg-white">
        <input
          type="checkbox"
          className="form-checkbox h-4 w-4 text-blue-600"
          checked={isSampleSelected}
          onChange={handleCheckboxChange}
        />
      </td>
      <td className="sticky left-0 z-10 bg-white">
        {sampleInfo?.sample_location?.name || "N/A"}
      </td>
      {hasUniqueIds && <td>{sampleInfo?.name || "-"}</td>}
      <td className="text-center">{displayedDepth}</td>
      <td>{sampleInfo?.sample_location_group_name || "-"}</td>
      <td>{sampleInfo?.sample_location?.status || "-"}</td>
      <td className="text-center">{sampleInfo?.sample_date || "-"}</td>
      <td className="text-center">{sampleInfo?.matrix?.title || "-"}</td>
      {chemicalHeaders.map((chemical, i) => {
        const chemicalValue = chemicalValues.find(
          (value) =>
            value.chemical_id === chemical.id &&
            value.unit === chemical.unit &&
            value.analytical_method === chemical.analytical_method
        );
        return <ChemicalValue key={i} chemicalValue={chemicalValue} />;
      })}
    </tr>
  );
};

export default SaltPrintDataRow;
