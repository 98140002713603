import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'
const SET_SCALE_MODE = 'SET_SCALE_MODE'
const SET_CUSTOM_SCALE = 'SET_CUSTOM_SCALE'

const calculateDatasetMax = (sample) => {
  if (!sample || !sample.radar_data) return 50;
  return Math.max(...sample.radar_data.datasets.flatMap(dataset => dataset.data || []));
};

/* Initial States */
const initialState = (initialData) => ({
  originalGeoJSON: initialData?.initialGeoJSON || null,
  geojson: initialData?.initialGeoJSON || null,
  saltPrintSamples: initialData?.saltPrintSamples || [],
  saltPrint: initialData?.saltPrint || null,
  chemicals: initialData?.chemicals || [],
  site: initialData?.site || null,
  mapType: 'saltPrints',
  filteredSamples: null,
  filteredChemical: null,
  highlightSampleIds: [],
  displayedSamples: null,
  displayedGeojson: null,
  showPopups: false,
  showLegends: false,
  scaleMode: 'auto',
  customScale: null,
  datasetMaxValue: null,
  selectedSampleIds: []
})

/* Reducer */
const saltPrintReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value };
    case TOGGLE_POPUPS:
      return { ...state, showPopups: !state.showPopups };
    case TOGGLE_LEGENDS:
      return { ...state, showLegends: !state.showLegends };
    case SET_SCALE_MODE:
      return {
        ...state,
        scaleMode: action.value,
        customScale: action.value === 'custom' ? state.customScale : null
      };
    case SET_CUSTOM_SCALE:
      return {
        ...state,
        customScale: action.value,
        scaleMode: 'custom'
      };
    default:
      return state;
  }
}

/* Contexts */
const SaltPrintContext = createContext(initialState)
const SaltPrintAPI = createContext({})

/* Providers */
export const SaltPrintProvider = ({ children, initialData }) => {
  const [state, dispatch] = useReducer(saltPrintReducer, initialState(initialData))

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value });
    const setGeoJSON = (geojson) => updateState('geojson', geojson);
    const setSaltPrintSamples = (saltPrintSamples) => updateState('saltPrintSamples', saltPrintSamples);
    const setFilteredSamples = (filteredSamples) => updateState('filteredSamples', filteredSamples);
    const setFilteredChemical = (filteredChemical) => updateState('filteredChemical', filteredChemical);
    const setHighlightSampleIds = (highlightSampleIds) => updateState('highlightSampleIds', highlightSampleIds);
    const setDisplayedSamples = (displayedSamples) => updateState('displayedSamples', displayedSamples);
    const setDisplayedGeojson = (displayedGeojson) => updateState('displayedGeojson', displayedGeojson);
    const togglePopups = () => dispatch({ type: TOGGLE_POPUPS });
    const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS });
    const setScaleMode = (mode) => dispatch({ type: SET_SCALE_MODE, value: mode });
    const setCustomScale = (value) => {
      if (value >= 0) {
        dispatch({ type: SET_CUSTOM_SCALE, value: parseInt(value) });
      }
    };

    const addSelectedSampleId = (sampleId) => {
      if (!state.selectedSampleIds.includes(sampleId)) {
        dispatch({
          type: UPDATE_STATE,
          field: 'selectedSampleIds',
          value: [...state.selectedSampleIds, sampleId]
        });
      }
    };

    const removeSelectedSampleId = (sampleId) => {
      dispatch({
        type: UPDATE_STATE,
        field: 'selectedSampleIds',
        value: state.selectedSampleIds.filter(id => id !== sampleId)
      });
    };

    const setSelectedSampleIds = (ids) => {
      dispatch({
        type: UPDATE_STATE,
        field: 'selectedSampleIds',
        value: ids
      });
    };

    const clearSelectedSampleIds = () => {
      dispatch({
        type: UPDATE_STATE,
        field: 'selectedSampleIds',
        value: []
      });
    };

    const loadNextBatchSelect = async (samples, onProgress) => {
      const BATCH_SIZE = 5;
      const DELAY = 100;
      const totalSamples = samples.length;
      let currentSelectedIds = [...state.selectedSampleIds];

      for (let i = 0; i < samples.length; i += BATCH_SIZE) {
        const batch = samples.slice(i, i + BATCH_SIZE);
        await new Promise((resolve) => {
          setTimeout(() => {
            batch.forEach(sample => {
              if (!currentSelectedIds.includes(sample.id)) {
                currentSelectedIds.push(sample.id);
              }
            });

            dispatch({
              type: UPDATE_STATE,
              field: 'selectedSampleIds',
              value: currentSelectedIds
            });

            const progress = Math.min((i + BATCH_SIZE) / totalSamples, 1);
            if (onProgress) onProgress(progress);
            resolve();
          }, DELAY);
        });
      }
    };

    return {
      setGeoJSON,
      setSaltPrintSamples,
      setFilteredSamples,
      setFilteredChemical,
      setHighlightSampleIds,
      setDisplayedGeojson,
      setDisplayedSamples,
      togglePopups,
      toggleLegends,
      setScaleMode,
      setCustomScale,
      addSelectedSampleId,
      removeSelectedSampleId,
      setSelectedSampleIds,
      clearSelectedSampleIds,
      loadNextBatchSelect,
      selectedSampleIds: state.selectedSampleIds
    };
  }, [state.selectedSampleIds]);

  useEffect(() => {
    if (initialData?.saltPrintSamples) {
      dispatch({
        type: UPDATE_STATE,
        field: 'saltPrintSamples',
        value: initialData.saltPrintSamples
      });
    }
  }, [initialData?.saltPrintSamples]);

  useEffect(() => {
    const maxValue = calculateDatasetMax(state.saltPrintSamples);
    if (maxValue !== state.datasetMaxValue) {
      dispatch({
        type: UPDATE_STATE,
        field: 'datasetMaxValue',
        value: maxValue
      });
    }
  }, [state.saltPrintSamples]);

  return (
    <SaltPrintAPI.Provider value={api}>
      <SaltPrintContext.Provider value={state}>
        {children}
      </SaltPrintContext.Provider>
    </SaltPrintAPI.Provider>
  )
}

/* Custom Context Hooks */
export const useSaltPrintContext = () => useContext(SaltPrintContext)
export const useSaltPrintAPI = () => useContext(SaltPrintAPI)