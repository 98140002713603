import React from 'react'

const SampleMetadata = ({ selectedSample }) => {
  return (
    <div className='bg-white h-full'>
      <h3 className='font-semibold mb-1 bg-gray-200 px-1'>Sample Metadata</h3>
      <div className='p-2'>
        <table className="table-fixed w-full">
          <tbody className='text-sm'>
            <tr>
              <td className='font-bold'>Sample Location:</td>
              <td>{selectedSample.sample_location.name}</td>
            </tr>
            <tr>
              <td className='font-bold'>Unique Identifier:</td>
              <td>{selectedSample.id}</td>
            </tr>
            <tr>
              <td className='font-bold'>Sample Depth:</td>
              <td>{selectedSample.sample_depth}</td>
            </tr>
            <tr>
              <td className='font-bold'>Sample Date:</td>
              <td>{selectedSample.sample_date}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SampleMetadata
