import '../css/application.css';
import Rails from "@rails/ujs";
Rails.start();

import ReactRailsUJS from "react_ujs";
import { viteConstructorRequireContext } from "../helpers/viteConstructorRequireContext"

const componentsRequireContext = import.meta.glob("../components/**/*.{js,jsx}", { eager: true })

ReactRailsUJS.getConstructor = viteConstructorRequireContext(componentsRequireContext)
