import React from 'react'
import { resultTextColor } from '../helpers/analysisHelper'

const AnalysisRow = ({ ratioName, ratio }) => {
  return (
    <tr className='text-xs text-left'>
      <td className=''>{ratioName}</td>
      <td className={`text-center ${resultTextColor(ratio.result)}`}>{ratio.value || 'N/A'}</td>
      <td className={`text-center ${resultTextColor(ratio.result)}`}>
        {ratio.result}
      </td>
    </tr>
  )
}

export default AnalysisRow
