import React from "react";

const ChartToggle = ({ showChart, setShowChart }) => {
  return (
    <div
      className={`w-6 h-52 bg-chart-tab z-20 ${showChart ? "rounded-r-lg" : "rounded-l-lg"} flex justify-center items-center text-sm font-semibold uppercase cursor-pointer hover:bg-blue-200 transition-all duration-200`}
      style={{ writingMode: "vertical-rl" }}
      onClick={() => setShowChart(!showChart)}
    >
      <i className={`fa fa-chevron-${showChart ? "left" : "right"} mb-2`} />
      Visualization
    </div>
  )
};

export default ChartToggle;