import React, { useState, useEffect } from "react";
import axios from "axios";
import { SaltPrintProvider } from "../../contexts/saltPrint";
import MainContent from "./mainContent";

const Index = ({ site, saltPrint, chemicals }) => {
  const initialGeoJSON = saltPrint.visualization_data;
  const [saltPrintSamples, setSaltPrintSamples] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    axios.get(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples.json`)
      .then((res) => {
        setLoading(false);
        setSaltPrintSamples(res.data);
      })
      .catch((err) => console.error("Error fetching salt print samples:", err));
  }, [site.id, saltPrint.id]);

  return (
    <div className='relative flex flex-col h-full'>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <span className="text-3xl text-gray-400 font-semibold">
            <i className="fa fa-spinner fa-spin" /> Loading Salt Prints...
          </span>
        </div>
      ) : (
        <SaltPrintProvider initialData={{initialGeoJSON, saltPrintSamples, saltPrint, chemicals, site}}>
          <MainContent
            site={site}
            saltPrint={saltPrint}
            showChart={showChart}
            setShowChart={setShowChart}
          />
        </SaltPrintProvider>
      )}
    </div>
  );
};

export default Index;