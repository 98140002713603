import React from 'react';

const TableRow = ({ row, prepareRow }) => {
  prepareRow(row);
  const { key, ...rest } = row.getRowProps();

  return (
    <tr key={key} {...rest}>
      {row.cells.map(cell => {
        const { key: cellKey, ...cellProps } = cell.getCellProps();
        return (
          <td key={cellKey} className="text-sm" {...cellProps}>
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
