export const buildParentSeries = (chemicals) => {
  const parentSeries = {name: 'Parent', data: []};
  const alkylSeries = {name: 'Alkyl Homologs', data: []};

  chemicals.forEach(chemical => {
    if (!chemical.label.startsWith('C')) {
      parentSeries.data.push(chemical.float_value);
      alkylSeries.data.push(0);
    } else {
      alkylSeries.data.push(chemical.float_value);
      parentSeries.data.push(0);
    }
  });

  return [parentSeries, alkylSeries];
};

export const buildCongenerSeries = (chemicals) => {
  const congenerSeries = [
    {name: 'Congener 1', data: []},
    {name: 'Congener 2', data: []},
    {name: 'Congener 3', data: []},
    {name: 'Congener 4', data: []},
    {name: 'Congener 5', data: []},
  ];

  chemicals.forEach((chemical) => {
    congenerSeries.forEach((congener, index) => {
      const congenerData = chemical.congeners[index];
      congener.data.push(congenerData ? congenerData : 0);
    });
  });

  return congenerSeries;
};

export const buildPahChart = (title, yAxisTitle, data, categories, unit) => {
  return {
    chart: {
      type: 'column',
      height: 270,
      width: 600,
    },
    title: {
      text: title,
      useHTML: true,
      style: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '10px',
      },
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
      tickInterval: 2,
      lineWidth: 0,
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -90,
        style: {
          fontSize: '8px',
        },
      },
      lineWidth: 0,
    },
    tooltip: {
      pointFormat: '{series.name}: {point.y} ' + unit,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        groupPadding: 0,
        pointPadding: 0,
      },
    },
    legend: {
      enabled: true,
      itemDistance: 0,
      itemMarginTop: 0,
      itemMarginBottom: 0,
      symbolHeight: 6,
      itemStyle: {
        fontSize: '9px',
      },
    },
    series: data,
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  };
};
