import React from "react";

const TableHeader = ({headerGroup }) => {
  const { key, ...rest } = headerGroup.getHeaderGroupProps()
  
  return (
    <tr key={key} {...rest}>
      {headerGroup.headers.map((column) => {
        const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
        return (
          <th key={columnKey} {...columnProps}>
            {column.render('Header')}
            {column.isSorted ? (
              <i className={`fa fa-sort-${column.isSortedDesc ? 'down' : 'up'} ml-1`} />
            ) : (
              <i className="fa fa-sort ml-1" />
            )}
          </th>
        );
      })}
    </tr>
  );
}

export default TableHeader;
