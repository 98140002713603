import React from 'react'
import AnalysisRow from './analysisRow'

const DiagnosticRatios = ({ data }) => {
  return (
    <>
    {data &&
      <div className='bg-white h-full'>
        <h3 className='font-semibold bg-gray-200 px-1'>Diagnostic Ratios</h3>
        <div className='p-2'>
          <table className="table-fixed w-full">
            <thead>
              <tr>
                <th className='w-7/12 font-semibold text-sm text-left'>PAH Ratio</th>
                <th className='w-2/12 font-semibold text-sm text-center'>Result</th>
                <th className='w-3/12 font-semibold text-sm text-center'>Notes</th>
              </tr>
            </thead>
            <tbody>
              <AnalysisRow
                ratioName='Fluoranthene/Pyrene'
                ratio={data.ratio_1}
              />
              <AnalysisRow
                ratioName='Phenanthrene/Anthracene'
                ratio={data.ratio_2}
              />
              <AnalysisRow
                ratioName='Benzo(a)anthracene/Chrysene'
                ratio={data.ratio_3}
              />
              <AnalysisRow
                ratioName='Indeno(1,2,3-cd)pyrene/Benzo(g,h,i)perylene'
                ratio={data.ratio_4}
              />
            </tbody>
          </table>
        </div>
      </div>
    }
    </>
  )
}

export default DiagnosticRatios
