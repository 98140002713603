import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import SaltPrintSample from './saltPrintSample';
import { useSaltPrintContext, useSaltPrintAPI } from '../../contexts/saltPrint';

const SaltPrintSamples = ({ showChart }) => {
  const { site, saltPrint, saltPrintSamples, selectedSampleIds } = useSaltPrintContext();
  const { setSaltPrintSamples, removeSelectedSampleId } = useSaltPrintAPI();
  const [loadedSamples, setLoadedSamples] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const shouldStopLoading = useRef(false);

  useEffect(() => {
    if (!saltPrintSamples || selectedSampleIds.length === 0) {
      setLoadedSamples([]);
      return;
    }

    const selectedSamples = saltPrintSamples.filter(sample => 
      selectedSampleIds.includes(sample.id)
    );

    const BATCH_SIZE = 5;
    const DELAY = 100;
    let currentIndex = 0;

    const loadNextBatch = () => {
      if (shouldStopLoading.current || currentIndex >= selectedSamples.length) {
        setIsLoading(false);
        shouldStopLoading.current = false;
        return;
      }

      const nextBatch = selectedSamples.slice(currentIndex, currentIndex + BATCH_SIZE);

      setLoadedSamples(prev => {
        const existingIds = new Set(prev.map(sample => sample.id));
        const uniqueNewSamples = nextBatch.filter(sample => !existingIds.has(sample.id));
        return [...prev, ...uniqueNewSamples];
      });

      currentIndex += BATCH_SIZE;

      setTimeout(() => {
        if (!shouldStopLoading.current) {
          loadNextBatch();
        }
      }, DELAY);
    };

    setIsLoading(true);
    shouldStopLoading.current = false;
    loadNextBatch();

    return () => {
      shouldStopLoading.current = true;
    };
  }, [saltPrintSamples, selectedSampleIds]);

  useEffect(() => {
    if (selectedSampleIds.length === 0) {
      shouldStopLoading.current = true;
      setLoadedSamples([]);
      setIsLoading(false);
    } else {
      setLoadedSamples(prev => prev.filter(sample => selectedSampleIds.includes(sample.id)));
    }
  }, [selectedSampleIds]);

  const deleteSaltPrintSample = (saltPrintSample) => {
    axios.delete(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples/${saltPrintSample.id}`)
      .then(() => {
        const newSamples = saltPrintSamples.filter(sample => sample.id !== saltPrintSample.id);
        setSaltPrintSamples(newSamples);
        removeSelectedSampleId(saltPrintSample.id);
        setLoadedSamples(prevSamples => prevSamples.filter(sample => sample.id !== saltPrintSample.id));
      
        if (newSamples.length === 0) {
          setIsLoading(false);
          shouldStopLoading.current = true;
        }
      })
      .catch((err) => {
        console.error("Error deleting sample:", err);
      });
  };

  if (!saltPrintSamples || !Array.isArray(saltPrintSamples)) {
    return <div>Loading samples...</div>;
  }

  return (
    <div className="max-h-full p-3">
      {loadedSamples.length > 0 ? (
        <div className={`grid grid-cols-1 ${showChart ? "md:grid-cols-2" : "md:grid-cols-3"} gap-4`}>
          {loadedSamples.map(sample => (
            <div key={`sample-${sample.id}`} className="min-w-[420px] w-full">
              <SaltPrintSample
                saltPrintSample={sample}
                deleteSaltPrintSample={() => deleteSaltPrintSample(sample)}
              />
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No samples selected.</p>
      )}

      {isLoading && selectedSampleIds.length > loadedSamples.length && (
        <div className="text-center py-4">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          <p className="mt-2 text-gray-600">
            Loading samples... ({loadedSamples.length} of {selectedSampleIds.length})
          </p>
        </div>
      )}
    </div>
  );
};

export default SaltPrintSamples;