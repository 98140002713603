import React, { useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Select from "react-select";
import getChartConfig from "./chartConfig";
import { useSaltPrintContext, useSaltPrintAPI } from "../../contexts/saltPrint";

HighchartsMore(Highcharts);

const SaltPrintSample = ({ saltPrintSample, deleteSaltPrintSample }) => {
  const [type, setType] = useState(null)
  const chartRef = useRef(null);
  const { scaleMode, customScale, saltPrintSamples, selectedSampleIds } = useSaltPrintContext();
  const { setHighlightSampleIds, addSelectedSampleId, removeSelectedSampleId } = useSaltPrintAPI();

  const selectOptions = [{ label: "Target", value: "Target" }, { label: "Background", value: "Background" }]

  useEffect(() => {
    if (saltPrintSample) {
      const filteredType = selectOptions.filter(option => option.value === saltPrintSample.type )
      setType(filteredType[0])
    }
  }, [saltPrintSample]);

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    deleteSaltPrintSample();
  };

  const handleChartClick = () => {
    setHighlightSampleIds([saltPrintSample.id]);
  };

  const isSelected = selectedSampleIds.includes(saltPrintSample.id);

  const chartConfig = useMemo(() => {
    if (!saltPrintSample || !saltPrintSample.radar_data) return null;
    return getChartConfig(saltPrintSample, {
      backgroundColor: "transparent",
      scaleMode: scaleMode,
      customScale: customScale,
      allSamples: saltPrintSamples
    });
  }, [saltPrintSample, scaleMode, customScale, saltPrintSamples]);

  if (!chartConfig) return null;

  return (
    <div
      id={`sp-${saltPrintSample.id}`}
      className={`relative mb-2 border border-gray-300 rounded-lg hover:shadow-lg bg-white cursor-pointer ${
        isSelected ? "ring-2 ring-blue-500" : ""
      }`}
      onClick={handleChartClick}
    >
      <button
        className="absolute top-1 right-1 z-50 bg-red-500 text-white px-1.5 py-0.5 text-xs rounded hover:bg-red-600 transform hover:scale-105 transition-transform duration-200"
        style={{ pointerEvents: "auto" }}
        onClick={handleRemoveClick}
      >
        Remove
      </button>

      <div className="relative">
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={chartConfig}
          containerProps={{ className: "chart-container w-full" }}
        />
      </div>

      <div className="mt-4 p-2">
        <label className="block text-sm font-medium mb-1">Type:</label>
        <Select
          value={type}
          onChange={handleTypeChange}
          options={selectOptions}
          className="w-full"
          classNamePrefix="react-select"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

SaltPrintSample.defaultProps = {
  deleteSaltPrintSample: () => {}
};

export default React.memo(SaltPrintSample);