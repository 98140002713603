const getChartConfig = (saltPrintSample, options = {}) => {
  const { radar_data } = saltPrintSample;

  const calculateSampleMax = () => {
    const allValues = radar_data.datasets.flatMap((dataset) => dataset.data);
    const max = Math.max(...allValues.filter((val) => !isNaN(val)));
    return Math.ceil(max / 10) * 10;
  };

  const calculateGlobalMax = () => {
    if (!options.allSamples || !options.allSamples.length)
      return calculateSampleMax();
    const allValues = options.allSamples.flatMap(
      (sample) =>
        sample.radar_data?.datasets?.flatMap((dataset) => dataset.data) || []
    );
    const max = Math.max(...allValues.filter((val) => !isNaN(val)));
    return Math.ceil(max / 10) * 10;
  };

  const determineYAxisMax = () => {
    switch (options.scaleMode) {
      case "auto":
        return calculateSampleMax();
      case "max":
        return calculateGlobalMax();
      case "custom":
        return options.customScale || calculateGlobalMax();
      default:
        return calculateSampleMax();
    }
  };

  const yAxisMax = determineYAxisMax();

  return {
    chart: {
      polar: true,
      type: "polygon",
      backgroundColor: options.backgroundColor || "transparent",
      spacing: [80, 25, 25, 25],
      height: 500,
    },
    title: {
      text: `${saltPrintSample.sample.sample_location.name} @ ${parseFloat(
        saltPrintSample.sample.sample_depth
      ).toFixed(2)}m`,
      style: {
        fontSize: "22px",
        fontWeight: "bold",
      },
      margin: 25,
      y: -30,
    },
    subtitle: {
      text: `Date: ${saltPrintSample.sample.sample_date}`,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
      y: -10,
    },
    pane: {
      size: "100%",
      center: ["50%", "55%"],
    },
    xAxis: {
      categories: radar_data.labels,
      tickmarkPlacement: "on",
      lineWidth: 0,
      gridLineWidth: 1,
      labels: {
        style: {
          fontSize: "18px",
          fontWeight: "bold",
        },
        distance: 20,
      },
    },
    yAxis: {
      min: 0,
      max: yAxisMax,
      tickInterval: 10,
      endOnTick: true,
      showLastLabel: true,
      gridLineInterpolation: "polygon",
      gridLineColor: "#E6E6E6",
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {
        style: {
          fontSize: "18px",
        },
        distance: 15,
        formatter: function () {
          return this.value;
        },
      },
    },
    plotOptions: {
      series: {
        pointPlacement: "on",
        lineWidth: 2,
        animation: {
          duration: 300,
        },
      },
    },
    series: [
      {
        name: "Target",
        data: radar_data.datasets[0].data,
        type: "line",
        color: "#FF1493",
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        zIndex: 2,
      },
      {
        name: "Background",
        data: radar_data.datasets[1].data,
        type: "line",
        color: "#000080",
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        zIndex: 1,
      },
    ],
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: {
        fontSize: "19px",
        fontWeight: "bold",
      },
      y: 15,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };
};

export default getChartConfig;
